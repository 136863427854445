import React from 'react';
import ReactDOM from 'react-dom';

import 'bootstrap/dist/css/bootstrap.css';
import { MsalProvider } from '@azure/msal-react';
import { Configuration, PublicClientApplication } from '@azure/msal-browser';

import App from './App';
import errorMonitor from './utils/third-parties/error-monitor.service';
import * as axios from 'axios';
import { navigate } from '@reach/router';
import { saveNode } from 'utils/common';
import { RomeSwal } from 'components/alert';
const serviceWorker = require('./serviceWorker');
errorMonitor.initialize();

/**
 * Microsoft Authentication:
 * MSAL should be instantiated outside of the component tree to prevent it from being re-instantiated on re-renders.
 * For more, visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/getting-started.md
 */
// const { REACT_APP_AZURE_AD_CLIENT_ID } = process.env;
// const msalInstance = new PublicClientApplication({
//     auth: {
//         clientId: REACT_APP_AZURE_AD_CLIENT_ID || ''
//     }
// });

function retryFailedRequest(err: any) {
	if (err.status === 500 && err.config && !err.config.__isRetryRequest) {
		err.config.__isRetryRequest = true;
		return axios.default(err.config);
	}
	throw err;
}
axios.default.interceptors.response.use(
	function (response) {
		return response;
	},
	function (error) {
		if (error && error.response && error?.response?.status) {
			if (401 === error.response.status) {
				saveNode('rome_auth', {
					accessToken: null,
					expiresAt: -1,
					idToken: null,
					authUser: null,
				});

				// handle error: inform user, go to login, etc
				navigate('/auth', { state: 'Unauthorized' });
			}

			// Handle Bad Request
			if (400 === error.response.status) {
				throw error.response;
			}
		} else {
			RomeSwal.fire({
				icon: 'error',
				title: 'An error occurred',
				html:
					'Sorry, an error occurred trying to process your request. If the error continues, please let us know.',
			});
			retryFailedRequest(error);
			return Promise.reject(error);
		}
	}
);

// const AppProvider = () => (
//     <MsalProvider instance={msalInstance}>
//         <App />
//     </MsalProvider>
// );

ReactDOM.render(<App />, document.getElementById('root'));
serviceWorker.register();
