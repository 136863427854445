import { faArrowLeft, faDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useParams } from '@reach/router';
import BackLink from 'components/back-link/back-link.component';
import { flattenStages } from 'components/workflow/workflows/helpers';
import { useThemeContext } from 'context/useThemeContext';
import { useWorkflowContext } from 'context/useWorkflowStore';
import { last, padStart } from 'lodash';
import moment from 'moment';
import React, { Suspense, useState } from 'react';
import { Col, Container, FormGroup, Label, Row } from 'reactstrap';
import styled, { css } from 'styled-components';
import { Maybe } from 'types/globals';
import themeStore from '../../core-ui/models/ThemeStore';
import { useAuthContext, useGroupContext, User } from '../../utils/auth';
import { UserGroup } from '../accounts/types';
import Loading from '../loading.component';
import { Heading, Subheading } from '../ui';
import { CustomDatePicker } from '../workflow/workflows/components/workflow-details-tab-set/details-panel.styled-components';
import {
	Flattenable,
	WorkflowTemplate,
} from '../workflow/workflows/types/workflow.types';
import { MappedReportData, ReportData } from './status-report-table.component';
import enGB from 'date-fns/locale/en-GB';
import { registerLocale } from 'react-datepicker';

registerLocale('enGB', enGB);

const ReportsTableUserInterfaceView = React.lazy(() =>
	import('./status-report-table.component')
);
const RenderWhen = React.lazy(() => import('../render-when.component'));

const StyledContainer = styled(Container)`
	input,
	select {
		&:focus {
			color: #495057 !important;
			background-color: #ffffff !important;
			box-shadow: rgba(68, 55, 56, 0.5) 0px 0px 0px 4px !important;
			transition: box-shadow 0.1s linear 0s !important;
			outline: 0px !important;
			border-width: 1px !important;
			border-style: solid !important;
			border-color: rgb(146, 80, 65) !important;
			border-image: initial !important;
		}
	}
`;
export interface StatusReportFilters {
	dateTo?: Date | undefined;
	dateFrom?: Date | undefined;
	status?: string[];
	template?: string[];
}

interface StatusReportProps {
	renderReport: () => void;
}

const StatusReport = (props: StatusReportProps) => {
	const { id, title } = useParams();
	const [loading, setLoading] = useState(false);
	const { currentUser } = useAuthContext();
	const { groupsForCurrentUser } = useGroupContext();
	const { allTemplates, getReportData } = useWorkflowContext();
	const isUserOwnerOrFollower = (template: WorkflowTemplate) => {
		const isFollower =
			!!template.followers?.some((follower) => {
				return (
					follower._id === currentUser._id ||
					!!groupsForCurrentUser.some(
						(group: UserGroup) => group._id === follower._id
					)
				);
			}) ||
			!!((template.createdBy as User)._id === currentUser._id) ||
			!!template.owners?.some(
				(owner) =>
					owner._id === currentUser._id ||
					groupsForCurrentUser.some((group) => group._id === owner._id)
			);

		const isStakeholder = flattenStages(
			template as Flattenable
		)?.some((stage) =>
			stage?.owners?.some(
				(owner) =>
					owner._id === currentUser._id ||
					groupsForCurrentUser.some((grp) => grp._id === owner._id)
			)
		);
		return isFollower || isStakeholder;
	};

	const [filters, setFilters] = useState<StatusReportFilters>({});
	const setStatusFilter = (e: any) => {
		e.preventDefault();
		var options = e.target.options;
		var statuses: string[] = [];
		for (var i = 0, l = options.length; i < l; i++) {
			if (options[i].selected) {
				statuses.push(options[i].value);
			}
		}
		setFilters({ ...filters, status: statuses });
	};

	const setTemplateFilter = (e: any) => {
		e.preventDefault();
		var options = e.target.options;
		var templates: string[] = [];
		for (var i = 0, l = options.length; i < l; i++) {
			if (options[i].selected && !templates.includes(options[i].value)) {
				templates.push(options[i].value);
			}
		}
		setFilters({ ...filters, template: [...templates] });
	};

	const setToDateFilter = (e: Date | null) => {
		const toDate = e as Date;
		setFilters({ ...filters, dateTo: toDate });
	};
	const reset = () => {
		dispatchReportData({
			type: 'flush',
			payload: {
				reportData: { rows: [], header: '', columns: [] },
				mappedReportData: { headerObject: [], rowObject: [] },
				csvData: '',
				filename: '',
			},
		});
		setReportRendered(false);
		setFilters({
			template: [],
			dateFrom: undefined,
			dateTo: undefined,
			status: [],
		});
		props.renderReport();
	};

	const setFromDateFilter = (e: Date | null) => {
		const fromDate = e as Date;
		setFilters({ ...filters, dateFrom: fromDate });
	};

	const [reportData, dispatchReportData] = React.useReducer(function (
		currentReportData: MappedReportData,
		action: { type: 'set' | 'flush'; payload: MappedReportData }
	) {
		switch (action.type) {
			case 'set':
				return action?.payload ?? [];
			case 'flush':
				return action.payload ?? [];
		}
	},
	{} as MappedReportData);

	const toLabel = (label: { title?: string; key: string }): string => {
		if (label.title) {
			return label.title;
		}

		const [, key, val] = /^\${(.+?):(.+?)}$/.exec(label.key) || [];

		if (key && val) {
			return val;
		} else {
			return capitalizeFirstLetter(last(label?.key?.split('.')));
		}
	};
	const capitalizeFirstLetter = (str: string | undefined): string =>
		`${str?.charAt(0).toUpperCase()}${str?.slice(1)}`;

	const empty = '';
	const toCSVCell = (s: string) =>
		!s ? `"${empty.replace(/"/g, '""')}"` : `"${s?.replace(/"/g, '""')}"`;

	const [reportRendered, setReportRendered] = useState(false);

	const reportName = () => {
		const now = new Date();
		const padLeadingZero = (x: number) => padStart(`${x}`, 2, '0');
		const reportName = title.includes('Status')
			? `rome-workflow-report`
			: `rome-drawdown-report`;

		return `${reportName}-${[
			now.getUTCFullYear(),
			padLeadingZero(now.getUTCMonth() + 1), // mm starts at 0!
			padLeadingZero(now.getUTCDate()),
		].join('-')}.csv`;
	};

	/**
	 * @description
	 * method that will take all the rows back from the API that compile the report,
	 * and parse each row into a CSV appropriate string, reutrning the full file content
	 * to build the CSV file out of
	 */
	const buildCsvFile = () => {
		// @ts-ignore
		const header = reportData.reportData.columns
			.map((c) => toCSVCell(toLabel(c)))
			.join(',');

		// @ts-ignore
		const rows = reportData.mappedReportData.rowObject.map((layer) => {
			return layer.data
				.map((row: string) => row.replace('""', ''))
				.map((column: string) => {
					const parsedColumn = column
						.replace('"', '')
						.replace('"', '') as string;
					if (
						allTemplates &&
						allTemplates.length &&
						allTemplates.some(
							// @ts-ignore
							(template) => template._id.toString() === parsedColumn
						)
					) {
						const titleFilter = allTemplates.filter(
							// @ts-ignore
							(template) => (template as WorkflowTemplate)._id === parsedColumn
						);
						return titleFilter && titleFilter.length
							? toCSVCell(titleFilter[0].title)
							: toCSVCell(column);
					}
					return toCSVCell(column.replace('"', '').replace('"', ''));
				})
				.join(',');
		});
		return [header, ...rows].join('\n');
	};

	/**
	 * @description
	 * Method built to export the CSV file directly to the client;
	 * this method checks for if the browser is internet explorer; then uses internet explorers navigator.msSaveBlob method;
	 * else it will create a objectURL and manually download the csv
	 */
	const exportToCsv = () => {
		var blob = new Blob([buildCsvFile()], { type: 'text/csv' });
		if (navigator.msSaveBlob) {
			// IE 10+
			navigator.msSaveBlob(blob, reportName());
		} else {
			var link = document.createElement('a');
			// Browsers that support HTML5 download attribute
			var url = URL.createObjectURL(blob);
			link.setAttribute('href', url);
			link.setAttribute('download', reportName());
			link.style.visibility = 'hidden';
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
		}
	};

	const renderReport = () => {
		props.renderReport();
		setLoading(true);
		getReportData(id, filters.dateTo, filters.dateFrom, filters.status).then(
			(data: Maybe<ReportData>) => {
				if (data) {
					let rowObject = data.reportData.rows.map((row, index) => ({
						data: row.split('","').splice(0, 27),
						key: index,
					}));

					if (filters.template && filters.template?.length) {
						const filterableTemplate = filters.template.map((filter) => ({
							template: allTemplates?.find(
								(m: WorkflowTemplate) => m._id === filter
							),
						}));
						rowObject = rowObject.filter((filter) =>
							filterableTemplate.some(
								(template) =>
									template?.template?._id ===
									filter.data[filter.data.length - 1]
										.replace('"', '')
										.replace('"', '')
							)
						);
					}

					const headerdata = data.reportData.header
						.split('","')
						.map((header, idx) => ({
							display: header,
							index: idx,
						}));
					dispatchReportData({
						type: 'set',
						payload: {
							...data,
							mappedReportData: {
								rowObject: rowObject,
								headerObject: headerdata,
							},
							csvData: data.csvData,
							filename: data.filename,
						},
					});
				}
				setReportRendered(true);
				setLoading(false);
			}
		);
	};

	const isFetching = React.useMemo(() => !allTemplates, [allTemplates]);

	const { defaults } = useThemeContext();
	const secondaryStyle = css`
		font-size: 14px;
		display: inline-block;
		-webkit-appearance: none;
		border-color: initial;
		border-image: initial;
		border-radius: 4px;
		border-style: initial;
		border-width: 0px;
		box-sizing: border-box;
		color: #ffffff;
		cursor: pointer;
		line-height: inherit;
		margin: 0px;
		padding: 8px 16px;
		text-align: center;
		-webkit-text-decoration: none;
		text-decoration: none;
		margin: 1.5em 0;
		background: ${defaults?.secondary};
		&:hover {
			background: ${defaults?.secondaryHighlighted};
		}
	`;

	const SecondaryButton = styled.button`
		${secondaryStyle}
	`;

	return (
		<Suspense fallback={<Loading />}>
			<StyledContainer fluid={reportRendered} className="px-5">
				{isFetching && (
					<Loading
						label="Loading report parameters.."
						justifyContent="start"
						alignItems="center"
					/>
				)}
				<RenderWhen when={reportRendered}>
					<Row>
						<div className="d-flex col order-2 mb-2 align-items-end justify-content-end">
							<SecondaryButton onClick={() => exportToCsv()}>
								<FontAwesomeIcon icon={faDownload} className="mr-2" />
								<span>Download {themeStore._.report}</span>
							</SecondaryButton>
						</div>
						<div className="d-block order-1">
							<Subheading
								className="mb-2"
								style={{ cursor: 'pointer', letterSpacing: 1 }}
								onClick={() => reset()}
							>
								<FontAwesomeIcon icon={faArrowLeft} /> &nbsp; Back to Report
								Query Options
							</Subheading>
							<Heading>{title}</Heading>
						</div>
					</Row>
					<Row>
						<ReportsTableUserInterfaceView
							// @ts-ignore
							reportData={reportData}
							templates={allTemplates as WorkflowTemplate[]}
							filters={filters}
						/>
					</Row>
				</RenderWhen>
				<RenderWhen when={loading}>
					<div className="d-flex align-items-start justify-content-start">
						<Heading>Running Report</Heading>
						<Loading
							justifyContent={'start'}
							alignItems={'center'}
							label="Please wait.."
						/>
					</div>
				</RenderWhen>

				<RenderWhen when={!reportRendered && !loading && !isFetching}>
					<Row>
						<Col className="mx-auto" md={12}>
							<BackLink link="/admin/reports" title="Reports" />
							<Subheading>ROME</Subheading>
							<Heading>{title} Query</Heading>

							<FormGroup className="mt-2">
								<Col md={8}>
									<Label for="dateFrom">Date From</Label>

									<CustomDatePicker
										selected={filters.dateFrom}
										locale={'enGB'}
										className="form-control"
										placeholderText="Click to select a date"
										onChange={(e: Date) => setFromDateFilter(e)}
									/>
								</Col>
							</FormGroup>

							<FormGroup className="mt-2">
								<Col md={8}>
									<Label for="dateTo">Date To</Label>
									<CustomDatePicker
										selected={filters.dateTo}
										locale={'enGB'}
										className="form-control"
										placeholderText="Click to select a date"
										onChange={(e: Date) => setToDateFilter(e)}
									/>
								</Col>
							</FormGroup>

							<FormGroup className="mt-2">
								<Col md={8}>
									<Label for="dateFrom">Workflow Status</Label>
									<select
										onChange={(e: any) => setStatusFilter(e)}
										multiple={true}
										className="form-control"
									>
										<option aria-label="Active">Active</option>
										<option>Paused</option>
										<option>Cancelled</option>
										<option>Completed</option>
										<option>Pipeline</option>
									</select>
								</Col>
							</FormGroup>
							{title && title.includes('Status') && (
								<FormGroup className="mt-2">
									<Col md={8}>
										<Label for="dateFrom">Workflow Template</Label>
										<select
											multiple={true}
											onChange={(e: any) => setTemplateFilter(e)}
											className="form-control"
										>
											{allTemplates
												?.filter(isUserOwnerOrFollower)
												.map((template: WorkflowTemplate) => (
													<option
														key={template._id}
														value={template._id}
														aria-label={template.title}
													>
														{template.title}
													</option>
												))}
										</select>
									</Col>
								</FormGroup>
							)}
							<FormGroup className="mt-2">
								<Col md={8}>
									<SecondaryButton
										disabled={!allTemplates}
										onClick={renderReport}
									>
										<FontAwesomeIcon icon={faDownload} className="mr-2" />
										<span>Run {themeStore._.report}</span>
									</SecondaryButton>
								</Col>
							</FormGroup>
						</Col>
					</Row>
				</RenderWhen>
			</StyledContainer>
		</Suspense>
	);
};
export default StatusReport;
